@use "Styles/colors.module.scss" as colors;
@import "Styles/customScrollbar.module.scss";

.modalClosed {
  display: none;
}

.technologyPromptHelperContainer {
  margin: auto;
  background-color: colors.$findest-background-03;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 542px;

  .header {
    font-size: 20px;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0;
  }

  .question {
    h4 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    textarea {
      border-radius: 8px;
      border: 1px solid colors.$findest-gray;
      background: colors.$white;
      width: 100%;
      height: 40px;
      padding: 8px 12px;
      resize: none;
      @include customScrollbar;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}
