@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.querySearchResultsContainer {
  height: 100%;
  display: none;
  flex-direction: column;

  &.active {
    display: flex;
    height: calc(100% - 5rem);
  }

  .querySearchResultsHeader {
    display: flex;
    padding: 1rem 4rem;
  }

  .querySearchResultsContentHeader {
    margin: 0 4rem;
    display: flex;
    min-height: 40px;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $light-background-color;
    background-color: white;
    position: relative;

    &.isSearching {
      pointer-events: none;
    }

    .scrollingShadow {
      position: absolute;
      left: 4px;
      height: 1px;
      z-index: -1;
      width: calc(100% - 8px);
      transition: 0.3s ease-in-out;

      &.isScrolling {
        box-shadow: 0px 2px 10px 2px #00000010;
      }
    }

    .querySearchResultsTypeTabs {
      display: flex;
      margin-bottom: -1px;

      .querySearchResultsTypeTab {
        padding: 0 16px;
        height: 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border: 0 solid transparent;
        border-bottom-width: 2px;
        background-color: transparent;

        &:hover {
          border-color: $findest-gray;
        }

        &.active {
          border-color: $login-title-color;
          pointer-events: none;

          H4 {
            font-weight: 500;
          }
        }
        .loadingIndicatorSmall {
          margin-left: 8px;
        }
      }
    }

    .querySearchResultsContentOptions {
      display: flex;

      .querySearchResultsViewOptions {
        display: flex;

        .querySearchResultsViewOptionsDropdown {
          h6 {
            user-select: none;
            color: $findest-dark-gray;
            margin: 12px 0 2px 16px;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }
      }

      .querySearchResultSortBy{
        h6 {
          user-select: none;
          color: $findest-dark-gray;
          margin: 0 0 2px 16px;
        }

        .querySearchResultsSortByDropdown {
          display: flex;
          align-items: center;
          left:0;          

          h6 {
            margin-right: 8px;
          }
        }
      }
    }   
  }

  .querySearchResultLayout {
    display: flex;
    height: 100vh;
    max-height: calc(100% - 5rem);

    .querySearchResultsExtraContentContainer {
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      overflow-y: auto;
      overflow-x: hidden;
      @include customScrollbar;

      .technologyOverviewWizardContainer {
        padding: 27px 64px 12px 20px;
      }
      .chartContainer {
        padding: 20px 64px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 16px; 
        align-items: center;

        .header {
          display: flex;
          gap: 12px;
          height: 34px;
          align-items: center;
          align-self: flex-start;
          .technologyOverviewHeader {
            font-size: 1rem;
            font-weight: 600;
          }
        }

        .chart {
          width: calc(100% - 124px);
          @media (max-width: 1024px) {
            width: calc(100% - 62px);
          }
        }
        .wizardFooter {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
        }
      }
    }

    .querySearchResultsContentContainer {
      margin-top: 20px;
      padding: 0 64px 0;
      overflow-x: auto;
      width: 60%;
      @include customScrollbar;

      &.groupedDocumentResults {
        padding: 0 64px 0 0;
        width: 100%;
      }

      .loadingIndicatorContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 4rem;
      }

      .querySearchResultsContent {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;
      }

      .querySearchResultsContentTitle {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
      }
    }
  }

  .queryAffiliationsResult {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 0 16px 64px;
    overflow: hidden;
    @include customScrollbar;

    .loadingIndicatorContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 4rem;
    }

    .queryAffiliationHeader {
      display: flex;
      margin-bottom: 3px;
      h3 {
        font-size: 1.8em;
      }

      .backButton {
        margin-right: 24px;
      }

      .titleContainer {
        display: flex;
        align-items: center;
        .title {
          margin-right: 16px;
        }
      }
    }

    .divider {
      background-color: $light-background-color;
      height: 1px;
      margin-right: 64px;
    }
  }
  .technologySearchResults {
    display: flex;
    min-height: 1px;
    height: 100%;
    padding: 0 0 16px 0;

    &.isEmpty {
      height: 100%;
      margin: 27px 65px 105px;
      padding: 0;
    }

    .emptyTechnologyOverviewWizardContainer {
      background-color: $findest-background-03;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .wizardContainer {
        max-width: 521px;
      }
    }
    .loadingIndicatorContainer {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .description {
        font-size: 0.875rem;
      }
      .horizontalLoader {
        margin: 16px 0 4px;
      }
      .smallDescription {
        font-size: 0.75rem;
        font-style: italic;
      }
      .cancelSearchButton {
        margin-top: 16px;
      }
    }
  }
}
