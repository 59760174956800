@import "Styles/colors.module.scss";

.linkedCounts {
    width: 100%;
}

.countContainer {
    display: flex;

    .count {
        font-size: 14px;
        display: flex;
        background-color: $findest-blue;
        color: white;
        border-radius: 4px;
        align-items: center;
        margin-right: 12px;
        margin-top: 4px;
        cursor: pointer;
        
        &:last-of-type {
            margin-right: 0;
        }

        .iconContainer {
            display: flex;
            height: 20px;
            width: 20px;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 12px;
                margin: 0;
            }
        }

        h4 {
            margin-left: 2px;
            font-weight: 500;
            padding-right: 6px;
            letter-spacing: 0;
        }

        svg {
            font-size: 10px;
            margin-left: 2px;
        }

        &.study {
            background-color: $study-count-color;
        }

        &.entity {
            background-color: $entity-count-color;
        }

        &.document {
            background-color: $document-count-color;
        }

        &.highlight {
            background-color: $highlight-count-color;
        }

        &.attachment {
            background-color: $attachment-count-color;
            svg {
                transform: rotate(-45deg);
            }
        }

        &.comment {
            background-color: transparent;
            color: $main-black;
            svg {
                font-size: 14px;
            }

            &.list {
                margin: 0 -124px 0 auto;
            }
        }

        &.indexed {
            background-color: $attachment-count-color;
            cursor: auto;

            &.notIndexed {
                background-color: $red-200;
                color: $main-black;

                svg {
                    cursor: pointer;
                }
            }
        }
    }
}

.countPopupContainer {
    position: absolute;
    z-index: 2;
    box-shadow: -2px 2px 12px rgba(0,0,0,0.16);
    margin: 0;
    padding: 8px 32px 0 32px;
    max-width: 100%;
    left: 0;

    .countPopupTitle {
        font-weight: 500;
        letter-spacing: 0.144px;
        margin-bottom: 8px;
    }

    .imageContainer {
        padding: 1rem;
        border: 1px solid $main-gray;
        border-radius: 4px;
        margin-bottom: 16px;
        width: 50%;

        &:hover {
            border-color: $findest-gray;
        }

        .entityLikeImageContainer {
            position: relative;
            text-align: center;
            width: 100%;

            .entityLikeImageAspectRatioBox {
                height: 0;
                overflow: hidden;
                padding-bottom: 56.25%;
                position: relative;
                width: 100%;

                .entityLikeImageAspectRatioBoxContent {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .entityLikeImageCaption {
                margin-top: 0.5rem;
            }
        }

        .entityLikeImageCaption {
            margin-top: 4px;
            text-align: center;
        }
    }
    .highlightContainer:last-of-type {
        margin-bottom: 16px;
    }
}