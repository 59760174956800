@use "Styles/colors.module.scss" as colors;

.technologySearchFlowContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 64px;

  .technologySearchFlowHeader {
    font-size: 14px;
    font-weight: 600;
  }

  .technologySearchFlow {
    display: flex;
    font-size: 0.875rem;

    .documentSearchFlow {
      display: flex;
      align-items: center;
    }

    .documentSearchContainer {
      background-color: colors.$blue-100-50;
      padding: 4px 4px 4px 16px;
      display: inline-flex;
      align-items: center;
      gap: 16px;
      text-decoration: underline;
      border-radius: 8px;

      &.padding {
        padding: 8px 12px;
      }

      .documentSearchType {
        background-color: white;
        display: inline-flex;
        gap: 8px;
        align-items: center;
        padding: 5px 12px;
        border-radius: 4px;
        .documentSearchTypeIcon {
          color: colors.$findest-dark-gray;
        }
      }
    }

    .separatorArrow {
      margin: 0 8px;
    }
    
    .naturalLanguageQueryContainer {
      display: inline-flex;
      align-items: center;
      background-color: colors.$blue-100-50;
      padding: 8px 12px;
      border-radius: 4px;
      height: 34px;
      max-width: calc(100% - 164px);

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13.33px;
        letter-spacing: 0;
      }

      .editIcon {
        cursor: pointer;
        margin-left: 16px;
        color: colors.$findest-dark-gray;
      }
    }
  }
}
