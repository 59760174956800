@import "Styles/colors.module.scss";
@import "Styles/objectIcon.module.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 48px;
  width: 100%;

  .header {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .searchSection {
    display: flex;
    margin-bottom: 20px;

    .searchInputWrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 40px;

      .searchBox {
        flex: 1;
      }

      .searchButton {
        position: absolute;
        right: 0;
        bottom: 1;
        height: 32px;
        width: 32px;
        margin: 4px;

        background-color: #0d6efd;
        color: white;
      }
    }
  }

  .contentContainer {
    display: flex;
    flex: 1;
    gap: 20px;
    overflow: hidden;

    .chatContainer {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-right: 10px;

      .messageWrapper {
        width: 100%;
        display: flex;

        &:has(.user) {
          justify-content: flex-end;
        }

        &:has(.igor) {
          justify-content: flex-start;
        }

        .messageSection {
          padding: 15px;
          border-radius: 8px;
          max-width: 80%;

          &.igor {
            background-color: #e6f0ff;
          }

          &.user {
            background-color: #f0f0f0;
          }

          .contentLabel {
            display: block;
            font-weight: bold;
            margin-bottom: 8px;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .messageText {
            margin: 0;
            white-space: pre-wrap;
          }
        }
      }

      .loadingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        padding: 20px 0;

        .cancelButton {
          height: 32px;
          min-width: 80px;
          background-color: transparent;
          color: var(--text-color);
          border: 1px solid var(--border-color);

          &:hover {
            background-color: var(--hover-color);
          }
        }
      }
    }
  }
}

.referencesSection {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .referencesBox {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 15px;

    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 0.9rem;
      text-transform: uppercase;
    }

    .referencesList {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .referenceItem {
        width: 100%;
        max-width: unset;
        min-width: unset;
        position: relative;
        margin-right: 0;      

        &:hover {
          cursor: pointer;
          border-color: $findest-blue;
        }
      }
    }
  }
}

.outsideUniverseSection {
  margin-top: 30px;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  width: 100%;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

// Add these styles to ensure the CreateQueryWizard displays horizontally
:global {
  .createQueryWizard.simpleMode {
    .wizardsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
    }
  }
}

.loadingIndicatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
  margin-top: 10px;

  button {
    min-width: 120px;
  }
}