@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.overlay {
    &.hidden {
        display: none;
    }

    .container {
        display: flex;
        padding: 0;
        border-radius: 0;
        min-height: 664px;
        max-height: 664px;
        width: 976px;
        max-width: 96%;
    
        .header {
            position: absolute;
            right: 0;
            top: 0;
            margin: 10px 10px 0 0;
            z-index: 1;
    
            .minimizeButton, .closeButton {
                width: 32px;
                height: 32px;
                text-align: center;
                
                svg {
                    width: 16px;
                    height: 16px;
                    color: black;
                }
            }
        }
    
        .modalContainer {
            display: inline-flex;
            flex-direction: row;
        }

        .leftSection {
            width: 410px;
        }
    
        .rightSection {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: calc(100% - 410px);
            width: 566px;
            padding: 2rem;
            overflow-wrap: break-word;
            background-color: $grey-200;

            .noSourcesContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 24px;
                height: 100%;
                width: 400px;
                padding: 16px;
                margin: 0 auto;
                color: $main-black;

                
                svg[data-icon="link-slash"] {
                    width: 80px;
                    height: 80px;
                }

                h4 {
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow-x: hidden;
                @include customScrollbar;
                color: #171C21;
                font-size: 1rem;
                line-height: 1.5rem;
                letter-spacing: 0.512px;

                .disabled {
                    pointer-events: none;
                    opacity: .5;
                }

                .loadingIndicatorContainer {
                    width: 100%;
                    height: 100px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 32px;
                    gap: 16px;
                    p {
                        text-align: center;
                        max-width: 470px;
                    }
                }
    
                .generatedContentContainer {

                    &.displayNone {
                        display: none;
                    }

                    .generatedContent {
                        width: calc(100% + 6rem);
                        margin: 0 -3rem;

                        &.hasTabs {
                            margin-top: 4rem;
                        }
                        h1, p {
                            margin: 0 0 24px;
                        }
                    }
                    
                    .errorMessage {
                        border-radius: 4px;
                        padding: 16px;
                        background-color: $notification-red-25;
                        width: 100%;
                        margin: 3rem 0;
                    }
                }
            }

            .explanation {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 6px;
                width: 100%;
                padding: 4px 8px;
                border-radius: 8px;

                .explanationQuestion {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    width: 100%;
                    color: $findest-dark-gray;
                    cursor: pointer;

                    p {
                        font-size: 12px;
                        font-weight: 500;
                    }

                    svg {
                        width: 13px;
                        height: 13px;

                        &[data-icon="window-minimize"] {
                            margin-left: auto;
                        }
                    }
                }

                .explanationAnswer {
                    p, a {
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 18px;
                    }

                    a {
                        color: $findest-blue;
                        text-decoration: none;
                        font-weight: 600;
                    }
                }

                &.active {
                    padding: 4px 12px 8px;
                    background-color: $blue-100-50;
                    cursor: default;

                    .explanationQuestion {
                        color: $main-text-color;
                    }
                }

                &:hover {
                    background-color: $blue-100-50;
                    color: $main-text-color;
                }

            }

            .footer {
                display: flex;
                gap: 8px;

                button {
                    box-shadow: 0 -3px 10px $general-shadow-color;
                    
                    &.acceptButton {
                        background-color: $findest-blue;
                    }
                }

            }
        }
    }
}
