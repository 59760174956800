@use "Styles/colors.module.scss" as colors;

.technologySearchFields {
  display: flex;
  flex-direction: column;
  .description {
    font-size: 0.875rem;
    color: colors.$findest-dark-gray;
    max-width: 330px;
    line-height: 18.2px;
    font-weight: 400;
    letter-spacing: 0;
  
    &.queryDescription {
      margin: 8px 0;
    }

    &.queryHelp {
      color: colors.$findest-blue;
      font-size: 14px;
      font-weight: 700;
      margin-top: 12px;
    }
  
    &.criteriaDescription {
      margin: 5px 0;
    }
  }
  
  .criteriaMatchingHeading {
    margin-top: 16px;
  }
  
  .textArea {
    max-width: 500px;
    min-height: 104px;
    max-height: 120px;
  } 

  .warningContainer {
    margin-top: 16px;
  }
}
