@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.container {
  font-family: "IBM Plex Sans", sans-serif;

  .tableBuilder {
    &.displayNone {
      display: none;
    }

    .footer {
      display: flex;
      column-gap: 8px;
      width: fit-content;
      margin-top: 1rem;

      button {
        box-shadow: 0 -3px 10px $general-shadow-color;
        &.submitButton {
          background-color: $findest-blue;
        }
      }
    }
  }

  .tableDisplay {
    .existingObjectSuggestions {
      margin-bottom: 16px;
      .title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.2px;
        text-transform: none;
        letter-spacing: 0;
      }
    }

    .tableContainer {
      width: 500px;
      max-height: 484px;
      overflow: auto;
      @include customScrollbar;
      @include customScrollbarAllwaysDisplayed;

      .table {
        margin: 0;
        background-color: $white;
        letter-spacing: 0;
        font-size: 14px;
  
        .tableDocumentTitle {
          padding: 8px;
          div {
            display: flex;
            align-items: center;
            gap: 6px;
        
            svg {
              color: $main-text-color;
              cursor: pointer;
            }
        
            p {
              display: flex;
              align-items: center;
              gap: 8px;
              width: 186px;
              padding: 7px 8px;
              border-radius: 100px ;
              background-color: $blue-100;
              letter-spacing: 0;
              
              span {
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
              }
            }
          }
        }
    
        .tableDocumentInformation {
          min-width: 300px;
          padding: 8px;
          p {
            font-size: 14px;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}
