@import "Styles/colors.module.scss";

.container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-left: 10px;
    padding: 8px 12px;
    height: 34px;
    width: 130px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    box-shadow: $light-gray -1px 1px 10px;
    color: $purple-2;
    cursor: pointer;

    p {
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
    }
}