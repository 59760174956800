@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.container {
  height: 100%;

  .title {
    height: 62px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
    }

    svg {
      width: 25px;
      height: 20px;
    }
  }

  .optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &.emptyDocumentList {
      * {
        pointer-events: none;
      }
    }

    .options {
      padding: 0 1rem;
      .optionsTitle {
        display: flex;
        gap: 4px;
        color: $main-black;
        h4 {
          font-weight: 600;
          margin-bottom: 4px;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: default;
        }

        svg {
          margin-top: 2px;
          color: $findest-dark-gray;
        }
      }

      .sourcesContainer {
        display: flex;
        flex-direction: row;
        gap: 6px;
        & > h4 {
          font-size: 14px;
          font-weight: 600;

          &:nth-of-type(1) {
            width: 150px;
          }

          &:nth-of-type(2) {
            width: 170px;
          }

          &:nth-of-type(3) {
            width: 56px;
            margin-right: -8px;
          }
        }

        .sources {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 0 8px;
          background-color: $main-gray;
          border-radius: 100px;
          width: fit-content;
          height: 32px;

          &.open {
            background-color: $secondaryButtonColorHover;
          }

          &.noBackground {
            background-color: transparent;
          }

          .countContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
            gap: 4px;
            .count {
              display: flex;
              align-items: center;
              gap: 8px;
              border-radius: 4px;
              padding: 2px 4px;
              color: $white;
              height: 20px;
              font-size: 14px;

              &.document {
                background-color: $navy-blue;
              }

              &.highlight {
                background-color: $highlight-count-color;
              }
            }
          }

          svg {
            &.pointer {
              cursor: pointer;
            }
          }

          &.disabled {
            input {
              cursor: not-allowed;
            }
          }
        }
      }

      .textContainer {
        .questionBox {
          border: 2px solid $findest-blue;
          width: 380px;
          height: 122px;
          max-height: 122px;
          resize: none;
          padding: 8px;
          padding-bottom: 40px;
          border-radius: 8px;
          @include customScrollbar;
          &:focus {
            outline: none;
          }
        }

        .questionSubmit {
          position: relative;
          top: -42px;
          left: calc(100% - 38px);
          width: 2rem;

          &.disabled {
            background-color: $findest-gray;
            pointer-events: none;
            cursor: not-allowed;
            svg {
              background: transparent;
            }
          }
        }

        &.disabled {
          * {
            cursor: not-allowed;
            background-color: $main-gray;
            border: none;
            pointer-events: block;
          }

          .questionBox {
            border: 2px solid $grey-200;
            background-color: $main-gray;
          }

          .questionSubmit {
            background-color: $findest-gray;
            svg {
              background: transparent;
            }
          }
        }
      }

      .presetContainer.disabled {
        > * {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.3;
        }

        div.active {
          opacity: 1;
          pointer-events: auto !important;
          cursor: pointer;

          * {
            opacity: 1;
            pointer-events: auto !important;
            cursor: pointer;
          }
        }
      }
    }
  }

  .popover {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 478px;

    h4 {
      font-weight: 600;
      letter-spacing: 0;
    }

    a {
      color: $findest-blue;
      font-size: 14px;
      font-weight: 600;
      line-height: 8px;
      width: fit-content;
      text-decoration: none;
      letter-spacing: 0;

      svg {
        margin-left: 4px;
      }
    }

    .popoverItem {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      .popoverItemIcon {
        min-width: 36px;
        height: 16px;
        display: flex;
        gap: 8px;
        label {
          margin-top: 4px;
          width: 16px;
          height: 16px;
        }
        svg {
          margin-top: 6px;
          width: 12px;
          height: 12px;
          color: $navy-blue;
        }
      }

      p {
        font-size: 14px;
        font-weight: 450;
        cursor: default;
        letter-spacing: 0;
      }
    }
  }
}
