@import "Styles/colors.module.scss";

.documentFullTextIndexedContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  .information {
    background-color: $main-gray;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
    align-items: baseline;
    color: $main-text-color;

    p {
      width: calc(100% - 2rem);
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 600;
    }

    svg {
      width: 12px;
      height: 12px;
      color: $main-text-color;

      &[data-icon="circle-notch"] {
        animation: loader-spin 3s infinite linear;
      }

      @keyframes loader-spin {
        0% {
            transform: rotate(0deg);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
    }

    .indexingButton {
      background-color: transparent;
      border-radius: 4px;
      min-width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.notIndexed {
      background-color: $red-100;
      svg {
        color: $main-text-color;
        &[data-icon="file-exclamation"] {
          color: $red-400;
        }
      }

      .indexingButton:hover {
        background-color: $red-200;
      }
    }
  }
}

.informationModal {
  padding: 24px;
  width: 450px;

  div {
    p {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 20px;
    }

    h3 {
      font-size: 14px;
      margin-top: 14px;
      font-weight: 700;
      letter-spacing: 0;
    }

    .closeButton {
      background-color: #000;
      margin-top: 16px;
      letter-spacing: 0;
      font-size: 14px;
    }
  }
}
