@import "Styles/colors.module.scss";

.querySaveResultsContent {
    .querySaveResultsPopover {
      background-color: #0000006b;
      position: fixed;
      inset: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .querySaveResultsPopoverOverlay {
        background-color: $white;
        width: 512px;
      }
    }
  }