@import "Styles/colors.module.scss";

.documentGeneratedSummaryContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  .summary {
    background-color: $main-gray;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-image: linear-gradient(0deg, $main-gray, $main-gray),
      linear-gradient(160deg, #ffff00, #ffffff 20%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 8px;

    p,
    h3 {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 !important;
    }
  }
}
